import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import { Navbar } from "components/templates/shared/Navbar";
import { LineGraph } from "components/templates/shared/LineGraph";
import payloadOpenAPIGateway from "components/templates/shared/PayloadApiAWS";
import awsHeaders from "components/templates/shared/SignedRequest";
import axios from "axios";
import { useState } from "react";
import LoadingModal from "components/templates/shared/LoadingModal";

const ComeCotas = () => {

  interface ResponseData {
    data: any;
  }

  const [carregando, setCarregando] = useState(false);
  
  const [response, setResponse] = useState<ResponseData | null>(null);

  const comeCotas = (codigo: string | null) => {
    
    const data = {
      cd_anbima: codigo
    }

    const request = payloadOpenAPIGateway("/v1/anbima-rebate-come-cotas/rebate" as string, JSON.stringify(data) as string, 'application/json')

    const headers = awsHeaders(request);

    fetchData(data);

    async function fetchData(data: any) {
      const dados = {
        data: null,
      };
      const resp = await axios({
        method: request.method as any,
        baseURL: 'https://' + request.host,
        url: request.canonical_uri,
        data: data,
        headers: headers,
      }).then((response) => {
        console.log(response.data);
        setResponse(response.data);
        setCarregando(false);
      }).catch((error) => {
        alert('Erro ao buscar dados!');
        setResponse(null);
        console.log(error);
        setCarregando(false);
      });

    }

  }
  
  return (
    <>
      <Navbar isDark />

      <div className="relative h-[797px] lg:h-[800px]">
        <Section yPadding="py-0">
          <div className="relative flex">
            <div className="flex flex-col pt-[240px] mb-2 lg:mb-[2rem] lg:pt-[140px] md:max-w-[737px]">
 
              <div style={{width: "100%", maxWidth: "90%", height: "50px", margin: "0px auto;"}}>
                    <label htmlFor="nome">Código Ambima:&nbsp;</label>
                    <input
                      type="text"
                      id="nome"
                      name="nome"
                      style={{border: "solid", borderColor: "gray", borderWidth: "1px", borderRadius: "5px", padding: "5px", width: "50%"}}
                    />                    
                    <button onClick={() => {
                      const nomeElement = document.getElementById("nome") as HTMLInputElement | null;
                      console.log(nomeElement?.value);
                      if (nomeElement != null && nomeElement?.value != '') {
                        comeCotas(nomeElement.value);
                        setCarregando(true);
                      } else {
                        alert('Preencha um código ANBIMA válido!');
                        setResponse(null);
                      }
                    }} className="styles_btn__Umceq styles_btn-xl__9hGsT styles_btn-primary__XySJm" >
                        <span>Pesquisar</span> {/* Ícone de seta (←) */}
                        <LoadingModal open={carregando} />
                    </button>

                </div>

            </div>
          </div>
        </Section>

        <Section yPadding="py-0">
          <div style={{width: "100%", maxWidth: "90%", height: "500px", margin: "0px auto;"}}>

              <LineGraph data={response}/>

          </div>
        </Section>
        <Section yPadding="py-0">
          <div>
          <div style={{width: "100%", maxWidth: "90%", height: "100px", margin: "0px auto;"}}>
            </div>
          </div>
        </Section>
        <Footer />
      </div>
    </>
  );
};

export default ComeCotas;
