import { Footer } from "components/templates/shared/Footer";
import { Section } from "components/layout/Section";
import { Button } from "components/atoms/Button";
import { Link } from "react-router-dom";
import { forwardRef } from "react";
import { Navbar } from "components/templates/shared/Navbar";
import rectangle15 from "assets/images/for-you/rectangle15.png";
import Section1 from "pages/Partners/sections/Section1";
import Section2 from "pages/Partners/sections/Section2";
import Section3 from "pages/Partners/sections/Section3";
import Section4 from "pages/Partners/sections/Section4";
import Animation from "components/atoms/Animation";
import { leftToRight } from "utils/Animation.util";

const Partners = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <>
      <Navbar />
      <div className="overflow-auto antialiased text-black">
      <div className="relative h-[797px] lg:h-[800px] bg-[url('../assets/images/hero2.png')] bg-[right_-22rem_top] lg:bg-center  bg-no-repeat bg-cover lg:px-10">
        <Section yPadding="py-0">
          <div className="flex flex-col pt-[340px] mb-14 lg:mb-20 lg:pt-[240px] md:mb-[144px]">
            <Animation animationConfig={leftToRight(600)}>
              <h1 className="mb-6 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-white whitespace-pre-line leading-[120%]  max-w-[628px] lg:text-[80px]">
                Seja nosso <span className="text-primary">parceiro</span>
              </h1>
            </Animation>

            <Animation animationConfig={leftToRight(800)}>
              <div className="mb-7 lg:mb-10 md:max-w-[628px]">
                <p className="text-base leading-6 text-white lg:leading-8 lg:text-lg">
                  Ofereça produtos de previdência pensados para seus clientes,
                  em um <b>portal pensando para você.</b>
                </p>
              </div>
            </Animation>

            <Animation animationConfig={leftToRight(1000)}>
              <div>
                <Link
                  to={"https://parceiros.miovincipartners.com/"}
                  target="_blank"
                >
                  <Button xl>Quero ser um parceiro</Button>
                </Link>
              </div>
            </Animation>
          </div>
        </Section>
      </div>

      <Section>
        <div className="absolute right-0 flex -mt-16 overflow-hidden">
          <div className="lg:block">
            <img src={rectangle15} alt="Retângulo" />
          </div>
        </div>
      </Section>

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />

      <Footer />
      </div>
    </>
  );
});

export default Partners;
