
function payloadOpenAPIGateway(uri : string, data : string, content_type: string){
    const env = process.env.REACT_APP_ENV;
    return {
        "method": "POST",
        "service": "execute-api",
        "host": env === 'dev' ? process.env.REACT_APP_API_GATEWAY_HOST : (env === 'uat' ? process.env.REACT_APP_API_GATEWAY_HOST_UAT : process.env.REACT_APP_API_GATEWAY_HOST_PROD),
        "region": "us-east-1",
        "content_type": content_type,
        "canonical_uri": uri,
        "request_parameters": data
    }
}

export default payloadOpenAPIGateway;