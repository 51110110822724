import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    elements,
  } from 'chart.js';
  
  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
  );

  function LineGraph(dataset: any) {

    if (dataset.data == null) {
        return <div style={{ width: '1500px', maxWidth: '90%', height: '500px', margin: '0 auto' }}></div>;
    }

    const labels = dataset.data.datas;
    const data = {
        labels ,
        datasets: [
            {
                label: 'Acumulada',
                data: dataset.data.r_acumulada,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'red',
                borderWidth: 1
            },
            {
                label: 'Sem come-cotas',
                data: dataset.data.r_sem_comecotas,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'blue',
                borderWidth: 1
            },
        ],
    };

    const options = {
        responsive: true,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        plugins: {
            title: {
                display: false,
                text: 'Dataset',
            }
        },
    };    

    return (
            <div style={{ width: '1500px', maxWidth: '90%', height: '500px', margin: '0 auto' }}>
                    <Line data={data} options={options}/>
            </div>
        )
    }

export { LineGraph };

